








import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Observer
@Component({})
export default class PoolCountdown extends Vue {
  //Prop time is unix (X) format
  @Prop({ default: '' }) text!: string
  @Prop({ default: '' }) countDownStyle: any
  @Prop() time!: number
  @Prop() type!: 'start' | 'end'
  @Prop() callback!: () => void
  countDown = ''
  @Watch('type', { immediate: true }) onTypeChange() {
    this.init()
  }
  init() {
    const x = setInterval(() => {
      const currentTime = Number(moment().format('x'))
      const distance = Number(moment(this.time, 'X').format('x')) - currentTime
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      this.countDown = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`

      if (distance < 1) {
        clearInterval(x)
        this.callback()
        this.countDown = ''
      }
    }, 1000)
  }
}
